@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=swap');

:root{
  --font-base: "PT Sans Narrow", sans-serif;
}

body {
  margin: 0;
  background-color: #f0f8ff;
  font-family: var(--font-base) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  text-decoration: none;
  color: inherit;
}

/* change default mui */
.MuiPaper-root{
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 1px 3px 0px rgb(0 0 0 / 5%) !important;
}