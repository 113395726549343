/* -------------- version 1.0.0 -------------- */
/* display */
.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex !important;
}

.align-center {
    align-items: center !important;
}

.align-start {
    align-items: flex-start !important;
}

.align-end {
    align-items: flex-end !important;
}

.justify-between {
    justify-content: space-between !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-start {
    justify-content: start !important;
}

.justify-end {
    justify-content: end !important;
}

/* z index */
.z-index {
    z-index: 50 !important;
}
.z-index-20 {
    z-index: 20 !important;
}
.z-index-100 {
    z-index: 100 !important;
}
.z-index-full{
    z-index: 10000 !important;
}

/* text */
.text-select-disable {
    user-select: none;
}

.text-trans-none {
    text-transform: none !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-justify {
    text-align: justify !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}
.text-underline{
    text-decoration: underline;
}

.font-bold {
    font-weight: 600 !important;
}

/* position */
.pos-absolute {
    position: absolute !important;
}

.pos-fixed {
    position: fixed !important;
}

.pos-relative {
    position: relative !important;
}

.right-0 {
    right: 0;
}

.left-0 {
    left: 0;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

/*==== Padding ====*/
.p-0 {
    padding: 0px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-50 {
    padding: 50px !important;
}

.p-60 {
    padding: 60px !important;
}

.p-70 {
    padding: 70px !important;
}

.p-80 {
    padding: 80px !important;
}

.p-90 {
    padding: 90px !important;
}

.p-100 {
    padding: 100px !important;
}

.p-110 {
    padding: 110px !important;
}

.p-120 {
    padding: 120px !important;
}

.p-130 {
    padding: 130px !important;
}

.p-140 {
    padding: 140px !important;
}

.p-150 {
    padding: 150px !important;
}

.p-160 {
    padding: 160px !important;
}

.p-170 {
    padding: 170px !important;
}

.p-180 {
    padding: 180px !important;
}

.p-190 {
    padding: 190px !important;
}

.p-200 {
    padding: 200px !important;
}

.p-210 {
    padding: 210px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-2 {
    padding-top: 2px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pt-90 {
    padding-top: 90px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pt-110 {
    padding-top: 110px !important;
}

.pt-120 {
    padding-top: 120px !important;
}

.pt-130 {
    padding-top: 130px !important;
}

.pt-140 {
    padding-top: 140px !important;
}

.pt-150 {
    padding-top: 150px !important;
}

.pt-160 {
    padding-top: 160px !important;
}

.pt-170 {
    padding-top: 170px !important;
}

.pt-180 {
    padding-top: 180px !important;
}

.pt-190 {
    padding-top: 190px !important;
}

.pt-200 {
    padding-top: 200px !important;
}

.pt-210 {
    padding-top: 210px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pb-90 {
    padding-bottom: 90px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

.pb-110 {
    padding-bottom: 110px !important;
}

.pb-120 {
    padding-bottom: 120px !important;
}

.pb-130 {
    padding-bottom: 130px !important;
}

.pb-140 {
    padding-bottom: 140px !important;
}

.pb-150 {
    padding-bottom: 150px !important;
}

.pb-160 {
    padding-bottom: 160px !important;
}

.pb-170 {
    padding-bottom: 170px !important;
}

.pb-180 {
    padding-bottom: 180px !important;
}

.pb-190 {
    padding-bottom: 190px !important;
}

.pb-200 {
    padding-bottom: 200px !important;
}

.pb-210 {
    padding-bottom: 210px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.pl-70 {
    padding-left: 70px !important;
}

.pl-80 {
    padding-left: 80px !important;
}

.pl-90 {
    padding-left: 90px !important;
}

.pl-100 {
    padding-left: 100px !important;
}

.pl-110 {
    padding-left: 110px !important;
}

.pl-120 {
    padding-left: 120px !important;
}

.pl-130 {
    padding-left: 130px !important;
}

.pl-140 {
    padding-left: 140px !important;
}

.pl-150 {
    padding-left: 150px !important;
}

.pl-160 {
    padding-left: 160px !important;
}

.pl-170 {
    padding-left: 170px !important;
}

.pl-180 {
    padding-left: 180px !important;
}

.pl-190 {
    padding-left: 190px !important;
}

.pl-200 {
    padding-left: 200px !important;
}

.pl-210 {
    padding-left: 210px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pr-2 {
    padding-right: 2px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pr-70 {
    padding-right: 70px !important;
}

.pr-80 {
    padding-right: 80px !important;
}

.pr-90 {
    padding-right: 90px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.pr-110 {
    padding-right: 110px !important;
}

.pr-120 {
    padding-right: 120px !important;
}

.pr-130 {
    padding-right: 130px !important;
}

.pr-140 {
    padding-right: 140px !important;
}

.pr-150 {
    padding-right: 150px !important;
}

.pr-160 {
    padding-right: 160px !important;
}

.pr-170 {
    padding-right: 170px !important;
}

.pr-180 {
    padding-right: 180px !important;
}

.pr-190 {
    padding-right: 190px !important;
}

.pr-200 {
    padding-right: 200px !important;
}

.pr-210 {
    padding-right: 210px !important;
}


/* margin */
.m-auto {
    margin: auto;
}

.mt-inherit {
    margin-top: inherit !important;
}

.m-0 {
    margin: 0px !important;
}

.m-2 {
    margin: 2px !important;
}

.m-5 {
    margin: 5px !important;
}

.m-8 {
    margin: 8px !important;
}

.m-10 {
    margin: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.m-20 {
    margin: 20px !important;
}

.m-30 {
    margin: 30px !important;
}

.m-40 {
    margin: 40px !important;
}

.m-50 {
    margin: 50px !important;
}

.m-60 {
    margin: 60px !important;
}

.m-70 {
    margin: 70px !important;
}

.m-80 {
    margin: 80px !important;
}

.m-90 {
    margin: 90px !important;
}

.m-100 {
    margin: 100px !important;
}

.m-110 {
    margin: 110px !important;
}

.m-120 {
    margin: 120px !important;
}

.m-130 {
    margin: 130px !important;
}

.m-140 {
    margin: 140px !important;
}

.m-150 {
    margin: 150px !important;
}

.m-160 {
    margin: 160px !important;
}

.m-170 {
    margin: 170px !important;
}

.m-180 {
    margin: 180px !important;
}

.m-190 {
    margin: 190px !important;
}

.m-200 {
    margin: 200px !important;
}

.m-210 {
    margin: 210px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.mr-70 {
    margin-right: 70px !important;
}

.mr-80 {
    margin-right: 80px !important;
}

.mr-90 {
    margin-right: 90px !important;
}

.mr-100 {
    margin-right: 100px !important;
}

.mr-110 {
    margin-right: 110px !important;
}

.mr-120 {
    margin-right: 120px !important;
}

.mr-130 {
    margin-right: 130px !important;
}

.mr-140 {
    margin-right: 140px !important;
}

.mr-150 {
    margin-right: 150px !important;
}

.mr-160 {
    margin-right: 160px !important;
}

.mr-170 {
    margin-right: 170px !important;
}

.mr-180 {
    margin-right: 180px !important;
}

.mr-190 {
    margin-right: 190px !important;
}

.mr-200 {
    margin-right: 200px !important;
}

.mr-210 {
    margin-right: 210px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.ml-60 {
    margin-left: 60px !important;
}

.ml-70 {
    margin-left: 70px !important;
}

.ml-80 {
    margin-left: 80px !important;
}

.ml-90 {
    margin-left: 90px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.ml-110 {
    margin-left: 110px !important;
}

.ml-120 {
    margin-left: 120px !important;
}

.ml-130 {
    margin-left: 130px !important;
}

.ml-140 {
    margin-left: 140px !important;
}

.ml-150 {
    margin-left: 150px !important;
}

.ml-160 {
    margin-left: 160px !important;
}

.ml-170 {
    margin-left: 170px !important;
}

.ml-180 {
    margin-left: 180px !important;
}

.ml-190 {
    margin-left: 190px !important;
}

.ml-200 {
    margin-left: 200px !important;
}

.ml-210 {
    margin-left: 210px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mt-90 {
    margin-top: 90px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mt-110 {
    margin-top: 110px !important;
}

.mt-120 {
    margin-top: 120px !important;
}

.mt-130 {
    margin-top: 130px !important;
}

.mt-140 {
    margin-top: 140px !important;
}

.mt-150 {
    margin-top: 150px !important;
}

.mt-160 {
    margin-top: 160px !important;
}

.mt-170 {
    margin-top: 170px !important;
}

.mt-180 {
    margin-top: 180px !important;
}

.mt-190 {
    margin-top: 190px !important;
}

.mt-200 {
    margin-top: 200px !important;
}

.mt-210 {
    margin-top: 210px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-4 {
    margin-bottom: 4px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.mb-90 {
    margin-bottom: 90px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.mb-110 {
    margin-bottom: 110px !important;
}

.mb-120 {
    margin-bottom: 120px !important;
}

.mb-130 {
    margin-bottom: 130px !important;
}

.mb-140 {
    margin-bottom: 140px !important;
}

.mb-150 {
    margin-bottom: 150px !important;
}

.mb-160 {
    margin-bottom: 160px !important;
}

.mb-170 {
    margin-bottom: 170px !important;
}

.mb-180 {
    margin-bottom: 180px !important;
}

.mb-190 {
    margin-bottom: 190px !important;
}

.mb-200 {
    margin-bottom: 200px !important;
}

.mb-210 {
    margin-bottom: 210px !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.fit-content{
    max-width: fit-content;
}
/* border */
.border-left{
    padding-left: 15px;
    border-left: 4px solid #ffb606;
    text-transform: uppercase;
}
.border-primary-2 {
    border: 2px solid #ffb606;
}

.border-sec-2 {
    border: 2px solid #002147;
}
.border-gray-1 {
    border: 1px solid #a4a4a4;
}
.border-white-2 {
    border: 2px solid #9600FF;
}

.border-circle {
    border-radius: 50%;
}

.border-radious-5 {
    border-radius: 5px !important;
}

.border-radious-10 {
    border-radius: 10px !important;
}

.border-radious-25 {
    border-radius: 25px !important;
}

.border-radious-30 {
    border-radius: 30px !important;
}

/* cursor */
.cursor-pointer {
    cursor: pointer !important;
}

/* image */
.opacity-10 {
    opacity: 10%;
}

.opacity-20 {
    opacity: 20%;
}

.opacity-30 {
    opacity: 30%;
}

.opacity-40 {
    opacity: 40%;
}

.opacity-50 {
    opacity: 50%;
}

.opacity-60 {
    opacity: 60%;
}

.opacity-70 {
    opacity: 70%;
}

.opacity-80 {
    opacity: 80%;
}

.opacity-90 {
    opacity: 90%;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.obj-contain {
    object-fit: contain !important;
}

.object-cover {
    object-fit: cover !important;
}

.img-brt-50pr {
    filter: brightness(50%);
}

.img-brt-60pr {
    filter: brightness(60%);
}

/* Shadow  */
.shadow {
    box-shadow: 0 0 20px -12px rgba(0, 0, 0, 0.514) !important;
}

.shadow-1 {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.shadow-2 {
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.shadow-3 {
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
}

.shadow-4 {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
}

.shadow-5 {
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 5px 8px 0px, rgb(0 0 0 / 12%) 0px 1px 14px 0px;
}

/* color  */
.hov-primary:hover {
    color: #ffb606 !important;
}

.color-inherit {
    color: inherit !important;
}

.color-primary {
    color: #ffb606 !important;
}

.color-secondary {
    color: #002147 !important;
}

.color-blue {
    color: #0d6efd !important;
}

.color-black {
    color: #000 !important;
}

.color-dark {
    color: #4d4d4d !important;
}

.color-gray {
    color: #6c757d !important;
}

.color-success {
    color: #37e030
}

.color-info {
    color: #0dcaf0
}

.color-danger {
    color: #dc3545 !important;
}

.color-orange {
    color: #FF8F00
}

.color-pink {
    color: #D100FF
}

.color-light {
    color: #D1D5DB !important;
}

.color-white {
    color: #fff !important;
}

/* font */
.pre-wrap{
    white-space: pre-wrap !important;
}
.font-inherit {
    font-size: inherit !important;
}

.font-bold {
    font-weight: 600 !important;
}

.font-italic {
    font-style: italic !important;
}

.font-10 {
    font-size: 10px !important;
}

.font-12 {
    font-size: 12px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-24 {
    font-size: 24px !important;
}

.font-26 {
    font-size: 26px !important;
}

.font-28 {
    font-size: 28px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-32 {
    font-size: 32px !important;
}

.font-34 {
    font-size: 34px !important;
}

.font-36 {
    font-size: 36px !important;
}

.font-38 {
    font-size: 38px !important;
}

.font-40 {
    font-size: 40px !important;
}

.font-42 {
    font-size: 42px !important;
}

.font-44 {
    font-size: 44px !important;
}

.font-46 {
    font-size: 46px !important;
}

.font-48 {
    font-size: 48px !important;
}

.font-50 {
    font-size: 50px !important;
}

.font-60 {
    font-size: 60px !important;
}

.font-70 {
    font-size: 70px !important;
}
.font-90 {
    font-size: 90px !important;
}


/* height */
.h-10 {
    height: 10px;
}

.h-15 {
    height: 15px;
}

.h-20 {
    height: 20px;
}

.h-25 {
    height: 25px;
}

.h-30 {
    height: 30px;
}

.h-35 {
    height: 35px;
}

.h-40 {
    height: 40px;
}

.h-45 {
    height: 45px;
}

.h-50 {
    height: 50px;
}

.h-55 {
    height: 55px;
}

.h-60 {
    height: 60px;
}

.h-65 {
    height: 65px;
}

.h-70 {
    height: 70px;
}

.h-75 {
    height: 75px;
}

.h-80 {
    height: 80px;
}

.h-85 {
    height: 85px;
}

.h-90 {
    height: 90px;
}

.h-95 {
    height: 95px;
}

.h-100 {
    height: 100px;
}

.h-110 {
    height: 110px;
}

.h-120 {
    height: 120px;
}

.h-130 {
    height: 130px;
}

.h-140 {
    height: 140px;
}

.h-150 {
    height: 150px;
}

.h-160 {
    height: 160px;
}

.h-170 {
    height: 170px;
}

.h-180 {
    height: 180px;
}

.h-190 {
    height: 190px;
}

.h-200 {
    height: 200px;
}

.h-210 {
    height: 210px;
}

.h-220 {
    height: 220px;
}

.h-230 {
    height: 230px;
}

.h-240 {
    height: 240px;
}

.h-250 {
    height: 250px;
}

.h-260 {
    height: 260px;
}

.h-270 {
    height: 270px;
}

.h-280 {
    height: 280px;
}

.h-290 {
    height: 290px;
}

.h-300 {
    height: 300px;
}

.h-310 {
    height: 310px;
}

.h-320 {
    height: 320px;
}

.h-330 {
    height: 330px;
}

.h-340 {
    height: 340px;
}

.h-350 {
    height: 350px;
}

.h-360 {
    height: 360px;
}

.h-370 {
    height: 370px;
}

.h-380 {
    height: 380px;
}

.h-390 {
    height: 390px;
}

.h-400 {
    height: 400px;
}

.h-410 {
    height: 410px;
}

.h-420 {
    height: 420px;
}

.h-430 {
    height: 430px;
}

.h-440 {
    height: 440px;
}

.h-450 {
    height: 450px;
}

.h-460 {
    height: 460px;
}

.h-470 {
    height: 470px;
}

.h-480 {
    height: 480px;
}

.h-490 {
    height: 490px;
}

.h-500 {
    height: 500px;
}

.h-510 {
    height: 510px;
}

.h-520 {
    height: 520px;
}

.h-530 {
    height: 530px;
}

.h-540 {
    height: 540px;
}

.h-550 {
    height: 550px;
}

.h-560 {
    height: 560px;
}

.h-570 {
    height: 570px;
}

.h-580 {
    height: 580px;
}

.h-590 {
    height: 590px;
}

.h-600 {
    height: 600px;
}
.h-100pr {
    height: 100% !important;
}
.h-100vh {
    height: 100vh;
}
.min-h-150 {
    min-height: 150px !important;
}
.min-h-180 {
    min-height: 180px !important;
}
.min-h-250 {
    min-height: 250px !important;
}
.mx-h-180{
    max-height: 180px !important;
}
.mx-h-250{
    max-height: 250px !important;
}
.mx-h-500{
    max-height: 700px !important;
}

/* width */
.w-10 {
    width: 10px;
}

.w-15 {
    width: 15px;
}

.w-20 {
    width: 20px;
}

.w-25 {
    width: 25px;
}

.w-30 {
    width: 30px;
}

.w-35 {
    width: 35px;
}

.w-40 {
    width: 40px;
}

.w-45 {
    width: 45px;
}

.w-50 {
    width: 50px;
}

.w-55 {
    width: 55px;
}

.w-60 {
    width: 60px;
}

.w-65 {
    width: 65px;
}

.w-70 {
    width: 70px;
}

.w-75 {
    width: 75px;
}

.w-80 {
    width: 80px;
}

.w-85 {
    width: 85px;
}

.w-90 {
    width: 90px;
}

.w-95 {
    width: 95px;
}

.w-100 {
    width: 100px;
}

.w-110 {
    width: 110px;
}

.w-120 {
    width: 120px;
}

.w-130 {
    width: 130px;
}

.w-140 {
    width: 140px;
}

.w-150 {
    width: 150px;
}

.w-160 {
    width: 160px;
}

.w-170 {
    width: 170px;
}

.w-180 {
    width: 180px;
}

.w-190 {
    width: 190px;
}

.w-200 {
    width: 200px;
}

.w-210 {
    width: 210px;
}

.w-220 {
    width: 220px;
}

.w-230 {
    width: 230px;
}

.w-240 {
    width: 240px;
}

.w-250 {
    width: 250px;
}

.w-260 {
    width: 260px;
}

.w-270 {
    width: 270px;
}

.w-280 {
    width: 280px;
}

.w-290 {
    width: 290px;
}

.w-300 {
    width: 300px;
}

.w-310 {
    width: 310px;
}

.w-320 {
    width: 320px;
}

.w-330 {
    width: 330px;
}

.w-340 {
    width: 340px;
}

.w-350 {
    width: 350px;
}

.w-360 {
    width: 360px;
}

.w-370 {
    width: 370px;
}

.w-380 {
    width: 380px;
}

.w-390 {
    width: 390px;
}

.w-400 {
    width: 400px;
}

.w-410 {
    width: 410px;
}

.w-420 {
    width: 420px;
}

.w-430 {
    width: 430px;
}

.w-440 {
    width: 440px;
}

.w-450 {
    width: 450px;
}

.w-460 {
    width: 460px;
}

.w-470 {
    width: 470px;
}

.w-480 {
    width: 480px;
}

.w-490 {
    width: 490px;
}

.w-500 {
    width: 500px;
}

.w-510 {
    width: 510px;
}

.w-520 {
    width: 520px;
}

.w-530 {
    width: 530px;
}

.w-540 {
    width: 540px;
}

.w-550 {
    width: 550px;
}

.w-560 {
    width: 560px;
}

.w-570 {
    width: 570px;
}

.w-580 {
    width: 580px;
}

.w-590 {
    width: 590px;
}

.w-600 {
    width: 600px;
}

.w-100pr {
    width: 100% !important;
}

.mx-w-none {
    max-width: 0px !important;
}

.mx-w-60 {
    max-width: 60px;
}

.mx-w-150 {
    max-width: 150px;
}

.mx-w-160 {
    max-width: 160px;
}

.mx-w-200 {
    max-width: 200px;
}

.mx-w-220 {
    max-width: 220px;
}

.mx-w-250 {
    max-width: 250px;
}

.mx-w-300 {
    max-width: 300px;
}
.mx-w-700 {
    max-width: 700px;
}

.min-w-none {
    min-width: 0px !important;
}

.min-w-100 {
    min-width: 100px !important;
}

.min-w-150 {
    min-width: 150px !important;
}

.min-w-200 {
    min-width: 200px !important;
}

.min-w-250 {
    min-width: 250px !important;
}

.min-w-300 {
    min-width: 300px !important;
}

/* background color */
.bg-inherit {
    background-color: inherit !important;
}

.bg-default {
    background-color: #f0f8ff !important;
}

.bg-primary {
    background-color: #ffb606 !important;
    color: #fff;
}

.bg-secondary-light {
    background-color: #003169 !important;
    color: #fff;
}

.bg-secondary-trans {
    background-color: #dbecff !important;
    color: #003169;
}

.bg-secondary {
    background-color: #002147 !important;
    color: #fff;
}

.bg-trans {
    background: rgba(255, 255, 255, 0.308) !important;
    backdrop-filter: blur(20px) !important;
}

.bg-gray {
    background-color: #6c757d !important;
    color: #fff;
}

.bg-blue {
    background-color: #0d6efd !important;
    color: #fff !important;
}

.bg-orange {
    background-color: #ff7b00 !important;
    color: #fff !important;
}
.bg-white {
    background-color: #fff !important;
}
.bg-dark-trans{
    background-color: rgba(0, 0, 0, 0.350);
}

/* Scroll */
.overflow-hidden {
    overflow: hidden !important;
}
