/* Other Class */
.jodit-wysiwyg{
  background-color: #fff;
}

.hover:hover{
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2) !important;
}
.hover-underline:hover{
  text-decoration: underline;
}
.table-border td{
  border: 1px solid #ffb606 !important;
  padding: 4px 6px;
}


.bottom-border::before {
  background: #ffb606 !important;
  content: "";
  bottom: -10px;
  height: 3px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
}

/* Change Mui Default Font-family to Custome Font */
.MuiFormControlLabel-label,
.MuiInputBase-root,
.MuiInputLabel-root,
.MuiTableCell-root,
.PrivatePickersYear-yearButton,
.MuiMenuItem-root{
  font-family: var(--font-base) !important;
  font-size: 18px !important;
}

.MuiTypography-root{
  font-family: var(--font-base) !important;
}

/* Mui Tooltip */
.MuiTooltip-tooltip {
  background: rgba(255, 255, 255, 0.308) !important;
  backdrop-filter: blur(20px) !important;
  color: #002147 !important;
}

/* Alert */
.bg-nav {
  background: rgba(255, 255, 255, 0.6) !important;
  backdrop-filter: blur(20px) !important;
}

/* Mui Date Picker  */
.MuiYearPicker-root .Mui-selected {
  color: #002147 !important;
  background-color: #ffb300 !important;
}

/* Hover */
.hov-primary-text:hover {
  color: #ffb300 !important;
}

.hov-secondary-text:hover {
  color: #002147 !important;
}

/* Text */
.p {
  font-size: 18px !important;
  line-height: 1.8;
  letter-spacing: 1px;
}

/* Custom Button  */
.MuiButton-root {
  font-family: inherit !important;
}

.MuiButton-endIcon {
  margin-left: 3px !important;
}

.btn-hov-bold:hover button {
  font-weight: 600 !important;
}

.btn-hov-black:hover button {
  color: rgb(54, 54, 54) !important;
}

/* Mui Field */
.checkbox-primary .Mui-checked {
  color: #ffb606 !important;
}

/* Normal Button Color  */
.btn {
  background-color: #7c7c7c2e !important;
  color: rgba(0, 0, 0, 0.699) !important;
  letter-spacing: 0.7pt !important;
}

.btn:hover {
  color: #fff !important;
  background-color: #7c7c7c8c !important;
}

.btn-outlined {
  border: 2px solid #7c7c7c !important;
  color: #4d4d4d !important;
  font-weight: 600 !important;
}

.btn-outlined:hover {
  background-color: #7c7c7c !important;
  color: #fff !important;
}

/* Success Button Color  */
.btn-success {
  background-color: #37e030 !important;
  color: #fff !important;
  letter-spacing: 0.7pt !important;
}

.btn-success:hover {
  background-color: #37e030 !important;
}

.btn-outlined-success {
  border: 2px solid #37e030 !important;
  color: #37e030 !important;
  font-weight: 600 !important;
}

.btn-outlined-success:hover {
  background-color: #37e030 !important;
  color: #fff !important;
}

/* Primary Button Color */
.btn-primary {
  background-color: #ffb606 !important;
  color: #002147 !important;
  font-weight: 600 !important;
  letter-spacing: 0.7pt !important;
}

.btn-primary:hover {
  background-color: #ffbb1c !important;
}

.btn-primary-outlined {
  border: 2px solid #ffb606 !important;
  color: #e29e00 !important;
  font-weight: 600 !important;
  letter-spacing: 0.7pt !important;
}

.btn-primary-outlined:hover {
  background-color: #ffb606 !important;
  color: #fff !important;
}

/* btn primary transperent */
.btn-primary-trans {
  background-color: #ffb20033 !important;
  color: #fdb300 !important;
  letter-spacing: 0.7pt !important;
}

.btn-primary-trans:hover {
  background-color: #ffb40633 !important;
}

/* Secondary Button Color */
.btn-secondary {
  background-color: #002147 !important;
  color: #fff !important;
  letter-spacing: 0.7pt !important;
}

.btn-secondary:hover {
  background-color: #002147 !important;
}

.btn-secondary-outlined {
  border: 2px solid #002147 !important;
  color: #002147 !important;
  font-weight: 600 !important;
}

.btn-secondary-outlined:hover {
  background-color: #002147 !important;
  color: #fff !important;
}

/* btn secondary transperent */
.btn-secondary-trans {
  background-color: rgba(0, 74, 158, 0.16) !important;
  color: #002147 !important;
  letter-spacing: 0.7pt !important;
}

.btn-secondary-trans:hover {
  background-color: rgba(0, 74, 158, 0.22) !important;
}

/* Danger Button Color */
.btn-danger {
  background-color: #ff0000 !important;
  color: #fff !important;
}

.btn-danger:hover {
  background-color: #ff0000 !important;
}

.btn-danger-trans {
  background-color: rgba(255, 38, 38, 0.16) !important;
  color: #ff0000 !important;
}

.btn-danger-trans:hover {
  background-color: rgba(255, 38, 38, 0.22) !important;
}


.btn-danger-outlined {
  border: 2px solid #ff0000 !important;
  color: #ff0000 !important;
  font-weight: 600 !important;
}

.btn-danger-outlined:hover {
  background-color: #ff0000 !important;
  color: #fff !important;
}


.btn-hov-sec:hover {
  font-weight: 600 !important;
  color: rgb(54, 54, 54) !important;
  background-color: #ffb606 !important;
}

.hov-trans {
  transition: transform 300ms ease-in-out 25ms !important;
}

.hov-trans:hover {
  transform: scale(1.1) !important;
}

/* input field */
.input-primary label.Mui-focused {
  color: #ffb300 !important;
}

.input-primary .MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #ffb300 !important;
}

.input-primary .MuiInput-underline:after {
  border-bottom-color: #ffb300;
}

.input-primary .MuiOutlinedInput-root fieldset {
  border-color: rgba(150, 150, 150, 0.867);
}

.input-primary .MuiOutlinedInput-root:hover fieldset {
  border-color: #ffb300;
}

.input-primary .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #ffb300;
}

/* input Secondary */
.input-secondary label.Mui-focused {
  color: #002147 !important;
}

.input-secondary .MuiInput-root:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid #002147 !important;
}

.input-secondary .MuiInput-underline:after {
  border-bottom-color: #002147;
}

.input-secondary .MuiOutlinedInput-root fieldset {
  border-color: rgba(150, 150, 150, 0.867);
}

.input-secondary .MuiOutlinedInput-root:hover fieldset {
  border-color: #002147;
}

.input-secondary .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #002147;
}

/* Height */
.max-h-520 {
  max-height: 520px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.img-full-srcn {
  object-fit: cover;
  width: 100%;
  height: calc(100vh - 44px) !important;
}

/* Scroll  */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.445);
  border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffb606;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d39400;
}

/* Loader  */
@keyframes ldio-9r47h8a95fh-o {
  0% {
    opacity: 1;
    transform: translate(0 0)
  }

  49.99% {
    opacity: 1;
    transform: translate(48px, 0)
  }

  50% {
    opacity: 0;
    transform: translate(48px, 0)
  }

  100% {
    opacity: 0;
    transform: translate(0, 0)
  }
}

@keyframes ldio-9r47h8a95fh {
  0% {
    transform: translate(0, 0)
  }

  50% {
    transform: translate(48px, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}

.ldio-9r47h8a95fh div {
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  top: 36px;
  left: 12px;
}

.ldio-9r47h8a95fh div:nth-child(1) {
  background: #ffb606;
  animation: ldio-9r47h8a95fh 1s linear infinite;
  animation-delay: -0.5s;
}

.ldio-9r47h8a95fh div:nth-child(2) {
  background: #002147;
  animation: ldio-9r47h8a95fh 1s linear infinite;
  animation-delay: 0s;
}

.ldio-9r47h8a95fh div:nth-child(3) {
  background: #ffb606;
  animation: ldio-9r47h8a95fh-o 1s linear infinite;
  animation-delay: -0.5s;
}

.loadingio-spinner-dual-ball-xjci8aerbe {
  width: 120px;
  height: 120px;
  display: inline-block;
  overflow: hidden;
}

.ldio-9r47h8a95fh {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-9r47h8a95fh div {
  box-sizing: content-box;
}